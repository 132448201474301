import React from 'react';
import styles from './App.module.scss';
import PageHeader from './components/PageHeader/PageHeader';
import PageBody from './components/PageBody/PageBody';
import { PrimaryButton, initializeIcons  } from '@fluentui/react';

import { MsalAuthenticationTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import {loginRequest} from "./services/authConfig";



export const App: React.FunctionComponent<{msalInstance: PublicClientApplication}> = ({msalInstance}) => {
  initializeIcons();
  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
      <div className={styles.App}>
        <PageHeader />
        <PageBody />
      </div>
      </MsalAuthenticationTemplate>
      <UnauthenticatedTemplate>
        <PrimaryButton onClick={()=>{window.location.href = window.location.href+"#/"}}>Sign in</PrimaryButton>
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};
