import { DefaultButton, IconButton, Label, Spinner, SpinnerSize, Stack, Text, TextField } from "@fluentui/react";
import { loginRequest } from "../../services/authConfig";
import useFetchWithMsal from "../../services/msalWebClient";
import styles from "./PageBody.module.scss";
import { useState } from "react";

const FeedbackPage : React.FunctionComponent<{}> = ()=>{
    const [loading, setLoading] = useState<boolean>(false);
    const { execute, data } = useFetchWithMsal(loginRequest.scopes, "Admin");
    
    function runFeedbackLoop(): void {
        setLoading(true);
        execute("GET", "Feedback").then(()=>{
            console.log(data);
            setLoading(false);
        });
    }

    return <div className={styles.feedbackDetailsComponent}>
    <Stack className={styles.feedbackHeader}>
        <Text>Run feedback cycle</Text>
    </Stack>
    <Stack className={styles.feedbackDetailsPage}>
        <Stack className={styles.feedbackDetailsPageColumn}>
            <Stack className={styles.feedbackDetailsPageColumnContent}>
                {loading ? 
                <Spinner size={SpinnerSize.large} label={"Analyzing RFQ's"} /> :
                data && 
                    <>
                        <Label>Analyzed {data.rfqIds?.length || 0} RFQ's, suggestions:</Label>
                        <Text className={styles.feedbackAnalysisResult}>{data.suggestedImprovement}</Text>
                        <Label>Suggested prompt: </Label>
                        <TextField multiline={true} height={500} value={data.suggestedPrompt} />
                        <Text>Analysis used {data.inputTokenCount} input and {data.outputTokenCount} output tokens</Text>
                    </>
                }
                <div className={styles.rfqSpacer}></div>
            </Stack>
            <Stack className={styles.rfqFooter}>
                <DefaultButton onClick={runFeedbackLoop}>Run feedback loop</DefaultButton>
            </Stack>
        </Stack>
    </Stack>
</div>
}
export default FeedbackPage;