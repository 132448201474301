import {
    DetailsList,
    Stack,
    TextField,
    Text,
    IconButton,
    IColumn,
    CheckboxVisibility,
    PrimaryButton,
    Label,
    Dialog,
    DialogFooter,
    DefaultButton,
    Spinner,
    SpinnerSize,
    IDropdownOption,
    SearchBox,
    List,
    Persona,
    IPersonaProps,
    NormalPeoplePicker,
    PersonaSize,
    CommandBarButton,
    IComboBoxOption,
    ComboBox,
    Checkbox,
    SelectableOptionMenuItemType
} from "@fluentui/react";
import styles from "./PageBody.module.scss";
import { useEffect, useState } from "react";
import useFetchWithMsal from "../../services/msalWebClient";
import { loginRequest } from "../../services/authConfig";

interface IrfqPageProps {
    item: any;
    onClose: (reload: boolean) => void;
    reload: () => void;
    navigate: (shift: number) => void;
    canNavigateDown: boolean;
    canNavigateUp: boolean;
}

interface ISearchFilter {
    key: string;
    checked: boolean;
    value: string;
}

const RFQPage: React.FunctionComponent<IrfqPageProps> = ({ item, onClose, reload, navigate, canNavigateDown, canNavigateUp }) => {
    const [rfqInformation, setRfqInformation] = useState<any>();
    const [clientInformation, setClientInformation] = useState<any>();
    const [orderLines, setOrderLines] = useState<any[]>();
    const [searchFields, setSearchFields] = useState<ISearchFilter[]>();
    const [partsSearchTerm, setPartsSearchTerm] = useState<string>("");
    const { execute } = useFetchWithMsal(loginRequest.scopes);
    const { execute: search, data: searchData } = useFetchWithMsal(loginRequest.scopes);
    const { execute: rescan } = useFetchWithMsal(loginRequest.scopes);
    const { execute: executeSearchFields, data: searchFieldsRaw } = useFetchWithMsal(loginRequest.scopes, "Parts");
    const { execute: executePartSearch, data: partsData } = useFetchWithMsal(loginRequest.scopes, "Parts");
    const { execute: executeUserSearch } = useFetchWithMsal(loginRequest.scopes, "Users");
    const [selectedOrderLine, setSelectedOrderLine] = useState<any>();
    const [relatedOrderLines, setRelatedOrderLines] = useState<any[]>();
    const [confirmRescanVisible, setConfirmRescanVisible] = useState<boolean>(false);
    const [isReloading, setIsReloading] = useState<boolean>(false);
    const [manualSearch, setManualSearch] = useState<boolean>(false);
    const [foundParts, setFoundParts] = useState<any[]>([]);
    const [selectedPart, setSelectedPart] = useState<any>();
    const [assignVisible, setAssignVisible] = useState<boolean>(false);
    const [assignedTo, setAssignedTo] = useState<IPersonaProps>();
    const [isAssigningTask, setIsAssigningTask] = useState<boolean>();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isCompleting, setIsCompleting] = useState<boolean>(false);
    const [rfqDetailsVisible, setRfqDetailsVisible] = useState<boolean>(true);

    useEffect(() => {
        if (item.status !== "Completed") {
            search("GET", `Search?customerName=${item.clientInformation.name}&status=Completed`);
        } else {
            setRelatedOrderLines([]);
        }
    }, [search, item]);

    useEffect(()=>{
        executeSearchFields("GET", "SearchFields");
    }, []);

    useEffect(()=>{
        executeSearchFields("GET", "SearchFields");
    }, [executeSearchFields]);

    useEffect(()=>{
        if(!!searchFieldsRaw && !!selectedOrderLine){
            setSearchFields(Object.keys(searchFieldsRaw).map((f: any)=>({
                key: f,
                value: selectedOrderLine[searchFieldsRaw[f]],
                checked: !!selectedOrderLine[searchFieldsRaw[f]]
            })));
            setPartsSearchTerm("");
        }
    }, [searchFieldsRaw, selectedOrderLine]);

    useEffect(() => {
        if (selectedOrderLine) {
            setRfqDetailsVisible(false);
        }
        setManualSearch(false);
    }, [selectedOrderLine])

    useEffect(() => {
        setFoundParts([]);
        setSelectedPart(undefined);
    }, [manualSearch]);

    useEffect(() => {
        setFoundParts(partsData);
    }, [partsData])

    useEffect(() => {
        if (searchData) {
            let arr: any[] = searchData;
            let orderLines = arr.reduce(function (flat, toFlatten) {
                return flat.concat(toFlatten.orderLines);
            }, []);

            setRelatedOrderLines(orderLines.map((o: any, index: number) => ({ key: `${item.id}_orderLine_${index}`, ...o })));
        }
    }, [searchData, item])

    useEffect(() => {
        setClientInformation(item?.clientInformation);
        setRfqInformation(item?.rfqInformation);
        setOrderLines(item?.orderLines.map((o: any, index: number) => ({ key: `${item.id}_relatedOrderLine_${index}`, ...o })));
        setSelectedOrderLine(undefined);
        setManualSearch(false);
        setAssignVisible(false);
        setIsAssigningTask(false);
        setRfqDetailsVisible(true);
    }, [item]);

    const mapProductToListItem = (item: any): IComboBoxOption => {
        return {
            key: item.id,
            text: generateProductToolTip(item) ?? "",
            
            title: generateProductToolTip(item),
            data: item,
            styles: { 
                optionText: { 
                    
                    whiteSpace: "preserve-breaks", 
                    wordWrap: "break-word", 
                    borderBottom: "1px #cccccc solid" 
                } 
            }
        };
    }

    const renderProductOption = (option?: any, hideDescription?: boolean) => {
        return <>
            {option?.id && <Text style={{fontWeight: "bold"}}>Product number: {option.id}</Text>}
            {hideDescription || <Text style={{ marginBottom: "5px" }}>{option?.mediaObjects}</Text>}
            
            {option?.template && <Text>Type: {option?.template}</Text>}
            {option?.subtype && <Text>Sub type: {option?.subtype}</Text>}
            {option?.size && <Text>Size: {option?.size} MM</Text>}
            {option?.pressureType && <Text>Pressure rating: {option?.pressureType} {option?.pressureValue}</Text>}
            {option?.body && <Text>Body material: {option?.body} ({option?.bodyAbbr})</Text>}
            {option?.trim && <Text>Trim material: {option?.trim} ({option?.trimAbbr})</Text>}
            {option?.seat && <Text>Seat material: {option?.seat} ({option?.seatAbbr})</Text>}
            {option?.searchText && <Text>Part number: {option?.searchText}</Text>}
            {option?.thirdFigure && <Text>Third figure: {option.thirdFigure}</Text>}
            {option?.score && <Text>Score: {option.score}</Text>}
            {/* {Object.keys(option).filter((key)=>!key.startsWith("_") && key !== "mediaObjects" && key !== "id" && !!option[key]).map((key)=>(<Text>{key}: {option[key as keyof Object]}</Text>))} */}
        </>

    }

    const renderProductReadout = (option: any) => {
        return <div className={`${styles.flexProductOption}`}>
            {/* <Text>{option.description}</Text> */}
            {/* {Object.keys(option).filter((key)=>!key.startsWith("_")).map((key)=>(<Text>{key}: {option[key as keyof Object]}</Text>))} */}
            
            {option?.typeOfComponent && <Text>Type: {option?.typeOfComponent}</Text>}
            {option?.subtype && <Text>Sub type: {option?.subtype}</Text>}
            {option?.size && <Text>Size: {option?.size} MM</Text>}
            {option?.pressureType && <Text>Pressure rating: {option?.pressureType} {option?.pressure}</Text>}
            {option?.materialBody && <Text>Body material: {option?.materialBody} ({option?.materialBodyAbbr})</Text>}
            {option?.materialTrim && <Text>Trim material: {option?.materialTrim} ({option?.materialTrimAbbr})</Text>}
            {option?.materialSeat && <Text>Seat material: {option?.materialSeat} ({option?.materialSeatAbbr})</Text>}
            {option?.partNumber && <Text>Part number: {option?.partNumber}</Text>}
            {option?.thirdFigure && <Text>Third figure: {option.thirdFigure}</Text>}
            {option?.certification && <Text>LRS Certificate: {option.certification}</Text>}
            
            {/* <Text>Type: {option.typeOfComponent}</Text>
            <Text>Size: {option.size ? `${option.size}MM"` : "Not specified"}</Text>
            <Text>Pressure rating: {option.pressure ? option.pressureType + option.pressure + (option.pressureType === "JIS" ? "K":"") : "Not specified"}</Text>
            <Text>Body material: {option.materialBody ? `${option.materialBody} (${option.materialBodyAbbr})` : "Not specified"}</Text>
            <Text>Disc material: {option.materialDisc ? `${option.materialDisc} (${option.materialDiscAbbr})` : "Not specified"}</Text>
            <Text>Number: {option.partNumber || "Not specified"}</Text> 
            <Text>Third Figure: {option.thirdFigure || "Not specified"}</Text>  */}
            </div>
    }


    const columns: IColumn[] = [
        {
            key: "description",
            minWidth: 100,
            name: "Description",
            onRender: (item) => (
                <Stack className={styles.rfqOrderLineRow} >
                    {item === selectedOrderLine ?
                        <div>
                            <div className={styles.flexRow}>
                                <div className={styles.flexProductOption}>
                                    <Label>Description from PDF</Label>
                                    <Text>{item.description}</Text>
                                </div>
                                <div className={styles.flexProductOption}>
                                    <Label>Matched product</Label>
                                    <Text>{item?.matchedProduct?.mediaObjects}</Text>

                                </div>
                            </div>
                            <div className={styles.flexRow}>
                                    {renderProductReadout(item)}
                                <div className={styles.flexProductOption}>
                                    {renderProductOption(item?.matchedProduct, true)}

                                </div>
                            </div>
                            <ComboBox 
                                disabled={item.status === "Completed"} 
                                allowFreeInput={false} 
                                allowFreeform={false} 
                                inputMode={"none"}
                                unselectable={"on"} 
                                onRenderOption={(props, defaultRender)=>
                                    <span className={styles.alternativeProductPicker}>{defaultRender!(props)}</span>}
                                contentEditable={false} 
                                useComboBoxAsMenuWidth={true} 
                                title={generateProductToolTip(selectedOrderLine?.matchedProduct)} 
                                selectedKey={item?.matchedProduct?.id}
                                className={styles.alternativeProductPicker}
                                style={{ wordWrap: "break-word", whiteSpace: "preserve-breaks", height: "auto" }}
                                styles={
                                    {
                                        optionsContainer: {
                                            maxHeight: "500px"
                                        },
                                        rootDisallowFreeForm: {
                                            whiteSpace: "preserve-breaks",
                                            wordWrap: "break-word"
                                        },
                                        container: {

                                        }
                                    }

                                } 
                                options={[...selectedOrderLine.options.map(mapProductToListItem), {key: "noMatch", text: "No matching product"} , { key: "manualSearch", text: "Other (search manually)" }]} text={"Select alternative product"}
                                onChange={handleProductSelect}></ComboBox>
                        </div>
                        :
                        <div className={styles.flexList}>
                            <Text title={generateOrderLineTooltip(item)}>{item.description}</Text>
                            <Text title={generateProductToolTip(item?.matchedProduct)}>{item.matchedProduct?.mediaObjects || "No match found"}</Text>
                        </div>

                    }
                </Stack>)
        },
        {
            key: "quantity",
            minWidth: 70,
            maxWidth: 90,
            name: "Quantity",
            onRender: (item) => {
                return <div>
                    {item === selectedOrderLine ?
                        <TextField suffix={"pcs"} value={item.quantity} onChange={(e: any, newValue?: string) => {
                            selectedOrderLine.quantity = newValue || "";
                            setOrderLines([...orderLines ?? []])
                        }} /> :
                        <span>{`${item.quantity} pcs`}</span>
                    }
                </div>
            }
        }
    ]

    function onSave(): void {
        setIsSaving(true);
        execute("PATCH", `Item/${item.id}`, { ...item, rfqInformation, clientInformation, orderLines, status: "In Progress" }).then(() => {
            setIsSaving(false);
            reload();
        });
    }

    function createQuote(): void {
        setIsCompleting(true);
        execute("PATCH", `Item/${item.id}`, { ...item, rfqInformation, clientInformation, orderLines, status: "Completed" }).then(() => {
            setIsCompleting(false);
            onClose(true);
        });
    }

    function rescanDocument(): void {
        setIsReloading(true);
        rescan("GET", `Rescan/${item.id}`).then(() => {
            reload();
            setIsReloading(false);
            setConfirmRescanVisible(false);
            setManualSearch(false);
        });

    }

    const handleProductSelect = (_e: any, item?: IDropdownOption) => {
        if (item?.key === "manualSearch") {
            setManualSearch(true);
        } else {
            selectedOrderLine.matchedProduct = item?.data;
            setOrderLines([...orderLines ?? []])
        }
    }

    const generateProductToolTip = (item: any) => {
        if (!item) {
            return undefined;
        }
        return `LITM: ${item.itemNumber}\r\n` +
            `${item.mediaObjects}\r\n` +
            `Type: ${item.template}\r\n` +
            `Sub type: ${item.subtype}\r\n` +
            `Size: ${item.size}\r\n` +
            `Pressure rating: ${item.pressureType} ${item.pressureValue}\r\n` +
            (!!item.body ? `Body material: ${item.body} (${item.bodyAbbr})\r\n` : "") +
            (!!item.trim ? `Trim material: ${item.trim} (${item.trimAbbr})\r\n` : "") +
            (!!item.seat ? `Seat material: ${item.seat} (${item.seatAbbr})\r\n` : "") +
            `IMPA: ${item.searchText}\r\n` +
            `Third figure: ${item.thirdFigure}\r\n` +
            (item.score ? `score: ${item.score}`:"");
    }

    const generateOrderLineTooltip = (item: any) => {
        if (!item) {
            return undefined;
        }
        var pressureString = item.pressureBar ? `PN${item.pressureBar}` : item.pressureKG ? `${item.pressureKG}K` : "Not specified";
        return `${item.description}\r\n` +
            `Size: ${item.sizeInMM ? item.sizeInMM + "MM" : "Not specified"}\r\n` +
            `Pressure rating: ${pressureString}\r\n` +
            `Material: ${item.materialFull || "Not specified"}\r\n`;
    }

    const applySearchPart = () => {
        setManualSearch(false);
        selectedOrderLine.options = [selectedPart, ...selectedOrderLine.options];
        selectedOrderLine.matchedProduct = selectedPart;
        setOrderLines([...orderLines ?? []])
    }

    const translateLabel = (label: string) => {
        if (!label) {
            return label;
        }
        return label.match(/^[a-z]+|[A-Z][a-z]*/g)?.map(function (x) {
            return x[0].toUpperCase() + x.substr(1).toLowerCase();
        }).join(' ');
    }

    useEffect(()=>{
        searchParts();
    }, [partsSearchTerm, searchFields]);

    let searchPartTimer: number;
    const searchParts = () => {
        if (searchPartTimer) {
            window.clearTimeout(searchPartTimer);
        }
        if (!!partsSearchTerm && partsSearchTerm.length > 2 || (!!searchFields && searchFields.filter((f)=>f.checked && !!f.value).length > 0)) {
            searchPartTimer = window.setTimeout(() => {
                executePartSearch("POST", "Search", Object.fromEntries([
                        ["SearchTerm", partsSearchTerm], 
                        ...searchFields!.filter((f)=>f.checked && !!f.value).map((f)=>([f.key, f.value]))
                    ])
                );
            }, 500);
        }
    }

    const renderPart = (item?: any, index?: number | undefined, isScrolling?: boolean | undefined) => {
        return <div className={item.id === selectedPart?.id ? styles.rfqProductOptionSelected : styles.rfqProductOption} onClick={() => {
            setSelectedPart(item);
        }}>
            <Stack className={styles.rfqPartRow} title={generateProductToolTip(item)}>
                <Label>{item.mediaObjects}</Label>
                <div>(<Text style={{fontWeight: "bold"}}>Item No: {item.itemNumber}</Text><Text>, IMPA: {item.searchText}, Third Figure: {item.thirdFigure})</Text></div>
            </Stack>
        </div>
    }

    const selectAssignedTo = (item?: IPersonaProps) => {
        setAssignedTo(item);
        return item || null;
    }

    const assignTask = () => {
        setIsAssigningTask(true);
        execute("PATCH", `Item/${item.id}`, { ...item, assignedTo: { id: assignedTo?.key, displayName: assignedTo?.text, email: assignedTo?.secondaryText } }).then(() => {

            reload();
            setAssignVisible(false);
            setIsAssigningTask(false);
        });
    }

    const resolveUsers = (filter: string, selectedItems?: IPersonaProps[]) => {
        if (searchPartTimer) {
            window.clearTimeout(searchPartTimer);
        }
        if (filter && filter.length > 2) {
            return new Promise<IPersonaProps[]>((resolve, reject) => {
                searchPartTimer = window.setTimeout(() => {
                    executeUserSearch("GET", `Search?searchTerm=${filter}`).then((data: any) => {
                        if (data && data.map) {
                            resolve(
                                data?.map((user: any) => (
                                    {
                                        key: user.id,
                                        text: user.displayName,
                                        secondaryText: user.email
                                    }
                                )) ?? []
                            );
                        } else {
                            resolve([]);
                        }

                    });
                }, 500);
            });
        }
        return [];
    }

    const updateFilterTerm = (filterName: string, filterValue: string) => {
        if(!!searchFields){
            const filters = [...searchFields ?? []];
            const toUpdate = filters.find((f)=>f.key === filterName);
            toUpdate!.value = filterValue;
            toUpdate!.checked = true;
            setSearchFields(filters);
        }
    }

    const updateFilterChecked = (filterName: string, checked: boolean) => {
        if(!!searchFields){
            const filters = [...searchFields ?? []];
            const toUpdate = filters.find((f)=>f.key === filterName);
            toUpdate!.checked = checked;
            setSearchFields(filters);
        }
    }

    return <div className={styles.rfqDetailsComponent}>
        <Stack className={styles.rfqHeader}>
            <Text>RFQ: {rfqInformation?.rfqNumber} - {clientInformation?.name} - {rfqInformation?.vessel} {`[${item.status}]`}</Text>
            <Stack className={styles.rfqHeaderButtons}>
                {item.status !== "Completed" && <IconButton iconProps={{ iconName: "Refresh" }} title={"Rescan document"} onClick={() => setConfirmRescanVisible(true)}></IconButton>}
                <IconButton disabled={!canNavigateDown} iconProps={{ iconName: "ChevronLeft" }} onClick={() => navigate(-1)} />
                <IconButton disabled={!canNavigateUp} iconProps={{ iconName: "ChevronRight" }} onClick={() => navigate(1)} />
                <IconButton iconProps={{ iconName: "ChromeClose" }} onClick={() => onClose(false)} />
            </Stack>
        </Stack>
        <Stack className={styles.rfqDetailsPage}>
            <Stack className={styles.rfqDetailsPageColumn}>
                <Stack className={styles.rfqDetailsPageColumnContent}>
                    <CommandBarButton style={{ paddingTop: "5px", paddingBottom: "5px", textAlign: "left" }} iconProps={{ iconName: rfqDetailsVisible ? "ChevronDown" : "ChevronRight" }} text={"RFQ/Client information"} onClick={() => setRfqDetailsVisible(!rfqDetailsVisible)} />
                    {rfqDetailsVisible &&
                        <Stack className={styles.rfqDetailsContainer}>
                            <Stack className={styles.rfqDetailsColumn}>
                                {rfqInformation && Object.getOwnPropertyNames(rfqInformation).map((prop) =>
                                    <TextField disabled={item.status === "Completed"} label={translateLabel(prop)} value={rfqInformation[prop]} onChange={(e: any, value: string | undefined) => { setRfqInformation({ ...rfqInformation, [prop]: value }) }} />
                                )}
                            </Stack>
                            <Stack className={styles.rfqDetailsColumn}>
                                {clientInformation && Object.getOwnPropertyNames(clientInformation).map((prop) =>
                                    <TextField disabled={item.status === "Completed"} label={translateLabel(prop)} value={clientInformation[prop]} onChange={(e: any, value: string | undefined) => { setClientInformation({ ...clientInformation, [prop]: value }) }} />
                                )}
                                {item?.assignedTo && <><Label>Assigned to</Label><div style={{ marginTop: "5px" }}><Persona size={PersonaSize.size24} text={item?.assignedTo.displayName} secondaryText={item.assignedTo.email} /></div></>}
                            </Stack>
                        </Stack>
                    }
                    <Stack className={styles.rfqProductsList}>
                        {item.status === "Completed" ?
                            <Stack className={styles.rfqPreviousOrdersComponent}>
                                <Stack horizontalAlign={"space-evenly"} horizontal={true}>
                                    <Stack style={{ width: "calc(50% - 30px)" }}><Label>RFQ Name</Label></Stack>
                                    <Stack style={{ width: "calc(50% - 30px)" }}><Label>Product</Label></Stack>
                                    <Stack style={{ width: "60px" }}><Label>Quantity</Label></Stack>
                                </Stack>
                                <Stack className={styles.rfqPreviousOrdersList}>
                                    {orderLines?.map((item) => (
                                        <Stack className={styles.rfqPreviousOrdersRow} horizontalAlign={"space-evenly"} horizontal={true}>
                                            <Text style={{ width: "calc(50% - 30px)", wordWrap: "break-word", overflow: "ellipsis" }} title={generateOrderLineTooltip(item)}>{item.description}</Text>
                                            <Text style={{ width: "calc(50% - 30px)", wordWrap: "break-word", overflow: "ellipsis" }} title={generateProductToolTip(item?.matchedProduct)}>{item?.matchedProduct?.mediaObjects ?? "No product selected"}</Text>
                                            <Text style={{ width: "50px", textAlign: "right", marginRight: "10px" }}>{item.quantity}</Text>
                                        </Stack>
                                    ))}
                                </Stack>
                            </Stack>
                            :
                            <DetailsList className={styles.rfqDetailsContainer} columns={columns} items={orderLines || []} checkboxVisibility={CheckboxVisibility.hidden} onRenderRow={(props, defaultRender) => {
                                return <div onClick={() => setSelectedOrderLine(props?.item)}>
                                    {defaultRender!(props)}
                                </div>

                            }} />
                        }
                    </Stack>

                    {/* {selectedOrderLine &&
                        <Stack>
                            <Label>{selectedOrderLine.description}</Label>
                            {manualSearch ?
                                <BasePicker onResolveSuggestions={searchParts} onItemSelected={applySearchPart} />
                                :
                                <Dropdown disabled={item.status === "Completed"} selectedKey={selectedOrderLine?.matchedProduct?.id} options={[...selectedOrderLine.options.map((i: any) => ({ key: i.id, text: `${i.id} - ${i.impa}: ${i.mediaObjects}`, title: `${i.id}: ${i.mediaObjects}`, data: i })), { key: "manualSearch", text: "Other (search manually)" }]} onChange={handleProductSelect} />
                            }
                        </Stack>
                    } */}

                    {relatedOrderLines && relatedOrderLines.length > 0 &&
                        <Stack className={styles.rfqPreviousOrdersComponent}>
                            <Label>Previously ordered by {clientInformation.name}: </Label>
                            <Stack horizontalAlign={"space-evenly"} horizontal={true}>
                                <Stack style={{ width: "50%" }}><Label>RFQ Name</Label></Stack>
                                <Stack style={{ width: "50%" }}><Label>Product</Label></Stack>
                            </Stack>
                            <Stack className={styles.rfqPreviousOrdersList}>
                                {relatedOrderLines.map((item) => (
                                    <Stack className={styles.rfqPreviousOrdersRow} horizontalAlign={"space-evenly"} horizontal={true}>
                                        <Text style={{ width: "50%", wordWrap: "break-word", overflow: "ellipsis" }} title={generateOrderLineTooltip(item)}>{item.description}</Text>
                                        <Text style={{ width: "50%", wordWrap: "break-word", overflow: "ellipsis" }} title={generateProductToolTip(item?.matchedProduct)}>{item.matchedProduct?.mediaObjects ?? "No product selected"}</Text>
                                    </Stack>
                                ))}
                            </Stack>
                        </Stack>
                    }
                    <div className={styles.rfqSpacer}></div>
                </Stack>
                <Stack className={styles.rfqFooter}>
                    <PrimaryButton iconProps={{ iconName: "PeopleAdd" }} text={"Assign"} onClick={() => setAssignVisible(true)} />
                    <PrimaryButton iconProps={{ iconName: "Save" }} text={"Save"} onClick={onSave} />
                    <PrimaryButton iconProps={{ iconName: "MailForward" }} text={"Create Quote"} onClick={createQuote} />
                </Stack>
            </Stack>
            <Stack className={styles.rfqDetailsPreview}>
                {item?.pdfBase64 && <iframe title={"PDF Document"} src={"data:application/pdf;base64," + item.pdfBase64} height={"100%"} width={"100%"} />}
            </Stack>
        </Stack>
        {(isSaving || isCompleting) &&
            <Dialog hidden={false}>
                <Spinner size={SpinnerSize.large} label={isCompleting ? "Completing RFQ" : "Saving"} />
            </Dialog>
        }
        <Dialog hidden={!confirmRescanVisible} dialogContentProps={{ title: "Re-scan document", subText: isReloading ? undefined : "Are you sure you want to re-scan the document?" }}>
            {isReloading ?
                <Spinner size={SpinnerSize.large} label={"Rescanning document"} />
                :
                <DialogFooter>
                    <PrimaryButton onClick={rescanDocument}>Yes</PrimaryButton>
                    <DefaultButton onClick={() => setConfirmRescanVisible(false)}>No</DefaultButton>
                </DialogFooter>
            }
        </Dialog>
        {manualSearch &&
            <Dialog hidden={!manualSearch} dialogContentProps={{ title: "Search Parts", subText: selectedOrderLine?.description, showCloseButton: true }} onDismiss={() => setManualSearch(false)} minWidth={800}>
                <div className={styles.searchFilterCollection}>
                    {searchFields && searchFields.length > 0 ? 
                    
                    <>
                        {searchFields.map((s: ISearchFilter)=>(
                            <div key={s.key} className={styles.searchFilterLine}>
                                <Checkbox label={s.key} checked={s.checked} onChange={(e, checked)=>{updateFilterChecked(s.key, !!checked)}} /> 
                                <TextField value={s.value} onChange={(e, newValue)=>{updateFilterTerm(s.key, newValue ?? "") }} />
                            </div>
                        ))}
                        <div key={"placeholder"} className={styles.searchFilterLine} />
                    </>
                    : 
                        <Spinner label={"loading filters"} size={SpinnerSize.medium} />
                    } 
                </div>
                <SearchBox onChange={(ev, newValue)=>{
                    setPartsSearchTerm(newValue ?? "");
                }} value={partsSearchTerm} placeholder={"Type at least 3 characters to start searching"} />
                <List items={foundParts?.map((part: any) => ({ ...part, key: part.id })) || []} onRenderCell={renderPart} style={{ height: "400px", overflow: "scroll" }} />
                <DialogFooter>
                    <PrimaryButton disabled={!selectedPart} onClick={applySearchPart}>Select</PrimaryButton>
                </DialogFooter>
            </Dialog>
        }
        {assignVisible &&
            <Dialog hidden={!assignVisible} dialogContentProps={{ title: "Assign to user", showCloseButton: true }} onDismiss={() => setAssignVisible(false)} minWidth={400}>
                {isAssigningTask ?
                    <Spinner size={SpinnerSize.large} label={"Assigning to user..."} />
                    :
                    <>
                        <NormalPeoplePicker itemLimit={1} onItemSelected={selectAssignedTo} onResolveSuggestions={resolveUsers} />
                        <DialogFooter>
                            <PrimaryButton disabled={!assignedTo} onClick={assignTask}>Select</PrimaryButton>
                        </DialogFooter>
                    </>
                }
            </Dialog>
        }
    </div>
}

export default RFQPage;