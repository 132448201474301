import { DetailsList, IColumn } from "@fluentui/react"
import FieldSetup, { IFieldItem } from "./FieldSetup"
import useFetchWithMsal from "../../services/msalWebClient"
import { loginRequest } from "../../services/authConfig"
import { useEffect, useState } from "react"

const FieldSetupList : React.FunctionComponent<{}> = ()=>{
    const {execute, data} = useFetchWithMsal(loginRequest.scopes, "Fields");
    const [items, setItems] = useState<IFieldItem[]>([]);
    const [selectedField, setSelectedField] = useState<IFieldItem>();

    const columns : IColumn[] = [
        {key: "Name", minWidth: 100, name: "Name", fieldName: "name"},
        {key: "databaseName", minWidth: 100, name: "Database name", onRender: (item)=>`${item.databaseField || item.databaseFieldAbbreviation || ""}`}
    ]

    useEffect(()=>{
        execute("GET", "List");
    }, [execute])

    useEffect(()=>{
        setItems(data || []);
    }, [data])

    return selectedField ? 
        <FieldSetup item={selectedField} onClose={(reloadList: boolean)=>{
            setSelectedField(undefined);
            if(reloadList){
                execute("GET", "List");
            }
        }} /> 
    : 
    <DetailsList 
        items={[...items, {name: "+ New field"}]} 
        columns={columns}
        onRenderRow={(props, defaultRender) => <div onClick={()=>setSelectedField(props?.item?.id ? props.item : {})}>{defaultRender!(props)}</div> } />
}

export default FieldSetupList;