import { Text, DefaultButton, Stack, TextField, IconButton, Toggle, ActionButton, SpinButton, Position, IComboBoxOption, ComboBox } from "@fluentui/react";
import { useEffect, useState } from "react";
import useFetchWithMsal from "../../services/msalWebClient";
import { loginRequest } from "../../services/authConfig";
import styles from "./PageBody.module.scss";

export interface IFieldItem {
    id: string;
    name: string;
    values?: string[];
    databaseField?: string;
    databaseFieldAbbreviation?: string;
    specialInstruction?: string;
    isExactMatch?: boolean;
    schema: string;
    scoreValue: number;
    showInSearch: boolean;
    fieldType: number;
}



const FieldSetup: React.FunctionComponent<{ item: IFieldItem, onClose: (reloadList: boolean) => void }> = ({ item, onClose }) => {
    const { execute } = useFetchWithMsal(loginRequest.scopes, "Fields");
    const [name, setName] = useState<string>("");
    const [specialInstruction, setSpecialInstruction] = useState<string>("");
    const [options, setOptions] = useState<string[]>([]);
    const [databaseFieldName, setDatabaseFieldName] = useState<string>("");
    const [databaseAbbreviationFieldName, setDatabaseAbbreviationFieldName] = useState<string>("");
    const [useAbbreviations, setUseAbbreviation] = useState<boolean>(false);
    const [useOptions, setUseOptions] = useState<boolean>(false);
    const [isExactMatch, setIsExactMatch] = useState<boolean>(false);
    const [scoreValue, setScoreValue] = useState<number>(1);
    const [showInSearch, setShowInSearch] = useState<boolean>(false);
    const [fieldType, setFieldType] = useState<number>(0);
    
    const FieldTypes: IComboBoxOption[] = [
        {
            key: "Filter",
            text: "Filter",
            data: 0
        },
        {
            key: "Score",
            text: "Score",
            data: 1
        },
        {
            key: "ScoreList",
            text: "Score List",
            data: 2
        }
    ];
    
    useEffect(() => {
        setName(item.name);
        setSpecialInstruction(item.specialInstruction || "");
        setOptions(item.values || []);
        setDatabaseFieldName(item.databaseField || "");
        setDatabaseAbbreviationFieldName(item.databaseFieldAbbreviation || "");
        setUseAbbreviation(!!item.databaseFieldAbbreviation);
        setUseOptions((item.values && (item.values.length > 0)) || false);
        setScoreValue(item.scoreValue);
        setShowInSearch(item.showInSearch);
        setFieldType(item.fieldType);
    }, [item]);

    const setValue = (newValue: string, index: number) => {
        let newArray = [...options];
        newArray[index] = newValue;
        setOptions(newArray);
    }

    const removeValue = (index: number) =>{
        let newArray = [...options];
        newArray.splice(index, 1);
        setOptions(newArray);
    }

    const saveField = () => {
        let newItem: IFieldItem = {
            ...item,
            name: name,
            databaseField: databaseFieldName,
            databaseFieldAbbreviation: useAbbreviations ? databaseAbbreviationFieldName : "",
            specialInstruction: specialInstruction!,
            values: options,
            isExactMatch,
            scoreValue,
            showInSearch,
            schema: useAbbreviations ? `"${name}": "string", "${name}Abbr": "string"` : `"${name}": "string"`
        };

        execute("POST", "UpsertField", newItem).then(() => {
            onClose(true);
        });
    }

    return <div className={styles.rfqDetailsComponent}>
        <Stack className={styles.rfqHeader}>
            <Text>Field: {name}</Text>
            <Stack className={styles.rfqHeaderButtons}>
                <IconButton iconProps={{ iconName: "ChromeClose" }} onClick={() => onClose(false)} />
            </Stack>
        </Stack>
        <Stack className={styles.rfqDetailsPage}>
            <Stack className={styles.rfqDetailsPageColumn} style={{gap: 8}}>
                <TextField value={name} label={"Field name"} onChange={(e, newValue: string | undefined) => setName(newValue || "")} />
                <ComboBox options={FieldTypes} label={"Field Type"} onChange={(e, option)=>setFieldType(option?.data ?? 0)} selectedKey={FieldTypes.find((f)=>f.data === fieldType)?.key} />
                <TextField value={databaseFieldName} label={"Database field name"} onChange={(e, newValue) => setDatabaseFieldName(newValue || "")} />
                <Toggle checked={useAbbreviations} inlineLabel label={"Use abbreviations"} onChange={(e, newValue) => setUseAbbreviation(newValue || false)} />
                {useAbbreviations && <TextField hidden={!useAbbreviations} value={databaseAbbreviationFieldName} label={"Database abbreviation field name"} onChange={(e, newValue) => setDatabaseAbbreviationFieldName(newValue || "")} />}
                <TextField value={specialInstruction} label={"Special instructions"} multiline={true} onChange={(e, newValue: string | undefined) => setSpecialInstruction(newValue || "")} />
                <SpinButton labelPosition={Position.top} value={`${scoreValue}`} label={"Scoring value"} min={0} max={10} onChange={(e, newValue)=>setScoreValue(Number(newValue || 0))} />
                <Toggle checked={showInSearch} inlineLabel label={"Show in product search"} onChange={(e, newValue) => setShowInSearch(newValue || false)} />
                <Toggle checked={useOptions} inlineLabel label={"Use fixed set of options"} onChange={(e, newValue) => setUseOptions(newValue || false)} />
                {useOptions && 
                    <>
                        {options?.map((option, index) => <TextField key={`options${index}`} value={option} onChange={(e, newValue) => setValue(newValue || "", index)} onRenderSuffix={(props, context)=><IconButton iconProps={{iconName: "RecycleBin"}} style={{height:16}} title={"Recycle"} onClick={()=>removeValue(index)} />} />)}
                        <ActionButton iconProps={{iconName: "CirclePlus"}} key={`newOption`} text={"new option"} onClick={() => setValue("", options.length)} />
                    </>
                    }
                <Toggle checked={isExactMatch} inlineLabel label={"Match whole value"} onChange={(e, newValue) => setIsExactMatch(newValue || false)} />
                <DefaultButton onClick={saveField}>Save</DefaultButton>
            </Stack>
        </Stack>
    </div>
}

export default FieldSetup;