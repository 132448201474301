import { useEffect, useState } from "react";
import styles from "./PageBody.module.scss";
import { CheckboxVisibility, DetailsList, Dialog, DialogFooter, IColumn, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import RFQPage from "./rfqPage";
import useFetchWithMsal from "../../services/msalWebClient";
import { loginRequest } from "../../services/authConfig";
import DragAndDrop from "./DragDropTarget";

const RfqList : React.FunctionComponent<{}> = ()=>{
    const { execute, data } = useFetchWithMsal(loginRequest.scopes);
    const { execute: getItemExecute, data: itemData } = useFetchWithMsal(loginRequest.scopes);
    const { execute: queueExecute } = useFetchWithMsal(loginRequest.scopes);
    const [isUploading, setIsUploading] = useState<boolean>();
    const [ uploadFailed, setUploadFailed] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>();
    const [orderBy, setOrderBy] = useState<string>();
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const { execute: rescanExecute } = useFetchWithMsal(loginRequest.scopes);
    useEffect(()=>{
        execute("GET", "List");
    }, [execute]);
    
const retryAll = async ()=>{
    let ids: string[] = data.map((o:any)=>o.id);
    for(let id of ids) {
        await rescanExecute("GET", `Rescan/${id}`);
    }
}

     const columns = () : IColumn[] => {
        let cols : IColumn[] = [
            {
                key: "Status",
                minWidth: 100,
                maxWidth: 100,
                name: "Status",
                fieldName: "status",
                flexGrow: 0,
                onColumnClick: ()=>{
                    setOrderBy("status")
                }
            },
            {
                key: "RFQ Number",
                minWidth: 100,
                maxWidth: 200,
                flexGrow: 1,
                name: "RFQ Nummer",
                onRender: (item)=>{
                    return item.rfqInformation.rfqNumber;
                },
                onColumnClick: ()=>{
                    setOrderBy("rfqNumber")
                }
            },
            {
                key: "Customer",
                minWidth: 200,
                maxWidth: 500,
                flexGrow: 1,
                name: "Client name",
                onRender: (item)=>{
                    return item.clientInformation.name;
                },
                onColumnClick: ()=>{
                    setOrderBy("clientInformation.name")
                }
            }, 
            {
                key: "AssignedTo",
                minWidth: 200,
                flexGrow: 1,
                name: "Assigned to",
                onRender: (item)=>{
                    return item.assignedTo?.displayName;
                },
                onColumnClick: ()=>{
                    setOrderBy("assignedTo.displayName")
                }
            }];
        return cols;
    }

    const getItems = ()=>{
        if(data){
            return orderBy ? data.sort((i1: any, i2: any)=>(getValue(i1, orderBy) > getValue(i2, orderBy) ? 1 : -1)) : data;
        }
        return [];
    }

    const getValue = (item: any, path: string) => {
        var parts = path.split(".");
        var value = item;
        parts.forEach((p)=>{if(value){value = value[p]}});
        return value ?? "";
    }

    const openRFQ = (item: any) =>{
        getItemExecute("GET", "Item/"+item.id);
    }

    useEffect(()=>{
        var items:any[] = getItems();
        var index = items.findIndex((i)=>i.id === itemData.id);
        setSelectedIndex(index);
        setSelectedItem(itemData);
    }, [itemData]);

    const uploadFile = (files: {[key:string]:string}) => {
        setIsUploading(true);
        queueExecute("POST", "Queue", {files}).then((result)=>{
            if((result?.status ?? 200) < 300) {
                setIsUploading(false);
                execute("GET", "List").then(()=>{});
            }
            else{
                setUploadFailed(true);
            }
                
        }).catch(()=>{
            setUploadFailed(true);
            
        });
    }

    const handleCloseItem = (reload: boolean)=>{
        setSelectedItem(undefined);
        if(reload){
            execute("GET", "List");
        }
    }

    const shiftSelectedRFQ = (shift: number) => {
        var items:any[] = getItems();
        var newSelected = items[selectedIndex+shift];
        if(newSelected){
            openRFQ(newSelected);
        }
        
    }

    return <>
        
        {selectedItem ?
        <RFQPage canNavigateDown={selectedIndex > 0} canNavigateUp={selectedIndex < data.length - 1} navigate={shiftSelectedRFQ} item={selectedItem} onClose={handleCloseItem} reload={()=>execute("GET", "List")} /> :
        
        <DragAndDrop onFilesDropped={uploadFile}>
            <div className={styles.rfqList}>
                {/* <PrimaryButton onClick={retryAll}>retry</PrimaryButton> */}
                <DetailsList
                    items={getItems()} 
                    checkboxVisibility={CheckboxVisibility.hidden}
                    columns={columns()} 
                    onRenderRow={(props, defaultRender) => <div onClick={()=>openRFQ(props?.item)}>{defaultRender!(props)}</div> } 
                />
            </div>
        </DragAndDrop>
        }
        <Dialog hidden={!isUploading} dialogContentProps={{showCloseButton: uploadFailed, title: "Upload file", subText:uploadFailed?"Document could not be scanned.":undefined}}>
            {uploadFailed ? 
                    <DialogFooter>
                        <PrimaryButton onClick={()=>{
                            setIsUploading(false);
                            setUploadFailed(false);
                            }} 
                        >Close</PrimaryButton>
                    </DialogFooter>
            :
                <Spinner size={SpinnerSize.large} label={"Scanning file..."} />
            }
        </Dialog>
    </>;
}

export default RfqList;