import { Pivot, PivotItem } from "@fluentui/react";
import RfqList from "./rfqList";
import FieldSetupList from "./FieldSetupList";
import styles from "./PageBody.module.scss";
import FeedbackPage from "./FeedbackPage";

const PageBody : React.FunctionComponent<{}> = ()=>{
    
    return <Pivot className={styles.body} styles={{itemContainer: {height: "100%"}}}>
        <PivotItem style={{height:"calc(100% - 55px)", overflow: "scroll"}} headerText={"RFQs"}><RfqList /></PivotItem>
        <PivotItem style={{height:"calc(100% - 55px)", overflow: "scroll"}} headerText={"Field Setup"}><FieldSetupList /></PivotItem>
        <PivotItem style={{height:"calc(100% - 55px)", overflow: "scroll"}} headerText={"Feedback loop"}><FeedbackPage /></PivotItem>
    </Pivot>
}

export default PageBody;